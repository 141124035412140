<script>
import {Overlay} from '../../@trading-vue-js/src/index'
import Volbar from "./volbar.js";

export default {
  name: 'VolumePrimary',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.0.2',
        desc: 'Volume Indicator',
        preset: {
          name: 'Volume',
          side: 'offchart',
          settings: {
            legend: false,
            legendButtons: false,
            histWidth: 2,
            lineWidth: 0.5,
            signalWidth: 1,
            color: 'rgba(39, 128, 227, 1)',
            lineColor: '#000',
            volumeColor: 'rgba(39, 128, 227, 1)'  //'#000' 
          }
        }
      }
    },
    draw(ctx) {
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.color
      ctx.beginPath()
      const layout = this.$props.layout?.master_grid
      // console.log("layout==>", layout);
      // console.log("this is layout from volume",this.$props?.layout.master_grid.volume)
      // VOLUME HISTOGRAM
      var cnv = {
          ohlcbars: this.$props.layout?.master_grid.candles,
          volume: this.$props.layout?.master_grid.volume
        }
        // console.log("cnv", cnv);
        // console.log("volumePrimary",cnv);
        if(cnv){
          var cv = cnv.volume;
        for (var j = 0, n = cv.length; j < n; j++) {
          new Volbar(this, ctx, cv[j]);
        }
        }
        ctx.stroke()
    },
    use_for() {
      return ['VolumePrimary']
    }
  },
  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 0.75
    },
    color() {
      return "#2780E3"
    },
    data_index() {
      return this.sett.dataIndex || 1
    },
    // Don't connect separate parts if true
    skip_nan() {
      return this.sett.skipNaN
    },
    show_volume() {
      return false
    },
    upVolBarColor() {
      return this.sett.upBarColor || '#23a77655'
    },
    downVolBarColor() {
      return this.sett.downBarColor || '#e5415055'
    },
    colorVolUp() {
      return this.sett.colorVolUp || this.$props.colors.volUp;
    },
    colorVolDw() {
      return this.sett.colorVolDw || this.$props.colors.volDw;
    },
  }
}
</script>