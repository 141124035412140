<script>
// Line drawing tool
import { Overlay, Tool } from '../../@trading-vue-js/src/index'
import Pin from './primitives/pin.js'
import Seg from './primitives/seg.js'
import {mapGetters} from "vuex";
import ToolSavingMixins from "./ToolSavingMixins";
export default {
  name: 'FibonacciRetrace',
  mixins: [Overlay, Tool,ToolSavingMixins],
  methods: {
    meta_info() {
      return { author: 'C451', version: '1.0.0' }
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Fibonacci',
        type: 'Segment',
        hint: 'This hint will be shown on hover',
        data: [], // Default data
        settings: {
          color: '#42b28a',
          lineWidth: 1,
          lineStyle: 'solid',
          settingsList: ['color', 'lineWidth', 'lineStyle'],

        } // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, 'p1'))
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, 'p2', {
          state: 'tracking'
        })
      )
      this.pins[1].on('settled', () => {
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'
        this.updateSettingsState(this)
        this.set_state('finished')
        this.$emit('drawing-mode-off')
        this.$emit('object-selected')
      })
      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      ctx.lineWidth = this.line_width * 1
      ctx.strokeStyle = this.color
      ctx.beginPath()

      //--- line style
      if (this.sett.lineStyle === 'dashed') {
        ctx.setLineDash([5, 10])
      } else if (this.sett.lineStyle === 'dotted') {
        ctx.setLineDash([2, 3])
      }

      let xFrom = this.p2[0]
      let xTo = this.p1[0]
      let yTop = this.p2[1]
      let yBottom = this.p1[1]
      // Top
      new Seg(this, ctx, '100.0%').draw([xFrom, yBottom], [xTo, yBottom])
      // Bottom
      new Seg(this, ctx, '0.0%').draw([xFrom, yTop], [xTo, yTop])
      ctx.stroke()
      // Inside
      ctx.beginPath()
      ctx.lineWidth = this.line_width
      let delta = yTop - yBottom
      for (const m of [0.236, 0.382, 0.5, 0.618, 0.764]) {
        new Seg(this, ctx, `${(m * 100.0).toFixed(1)}%`).draw(
          [xFrom, yTop - delta * m],
          [xTo, yTop - delta * m]
        )
      }
      // Above
      //   for (const m of [1.236, 1.382, 1.5, 1.618, 1.764, 2.618, 4.236]) {
      //     new Seg(this, ctx, `${(m * 100.0).toFixed(1)}%`).draw(
      //       [xFrom, yBottom + delta * m],
      //       [xTo, yBottom + delta * m]
      //     );
      //   }
      // Below
      //   for (const m of [1.236, 1.382, 1.5, 1.618, 1.764, 2.618, 4.236]) {
      //     new Seg(this, ctx, `${(m * 100.0).toFixed(1)}%`).draw(
      //       [xFrom, yTop - delta * m],
      //       [xTo, yTop - delta * m]
      //     );
      //   }

      ctx.stroke()
      ctx.setLineDash([])
      this.render_pins(ctx)
    },
    use_for() {
      return ['FibonacciRetrace']
    },
    data_colors() {
      return [this.color, this.fontColor]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['themePrimaryColor','themeSecondaryColor','themeFontColor']),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 0.9
    },
    color() {
      return this.sett.color || '#42b28a'
    },
    fontColor() {
      return this.sett.fontColor || '#40b28a'
    }
  },
  data() {
    return {}
  },
  created() {
    this.updateSettingsState(this)
  }
}
</script>
