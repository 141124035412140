<script>
import { mapGetters } from 'vuex';
import { Overlay } from '../../@trading-vue-js/src/index'
import greenArrow from '../../assets/arrows-png/green-arrow.png';
import redArrow from '../../assets/arrows-png/red-rrow.png';

export default {
  name: 'MACD',
  desc: 'MACD (12, 26, 9)',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.0.2',
        desc: 'Moving Average Convergence/Divergence',
        preset: {
          name: 'MACD (12, 26, 9)',
          side: 'offchart',
          settings: {
            histWidth: 4,
            macdWidth: 1,
            signalWidth: 1,
            // defColor: '#42b28a',
            macdColor: '#3782f2',
        signalColor: '#f48709',
        histColor: '#C0C0C0',
            
          }
        }
      }
    },
   
    
    draw(ctx) {
      const layout = this.$props.layout
      const settings = this.sett
      const base = layout.$2screen(0) + 0.5
      const off = this.hist_width % 2 ? 0 : 0.5
      ctx.lineWidth = this.hist_width
      ctx.strokeStyle = this.color
      ctx.beginPath()
      let prevP = this.$props.data[0]
      for (const p of this.$props.data) {
        const x = layout.t2screen(p[0]) - off
        const y = layout.$2screen(p[1]) - 0.5
        let color = 0
        const hist = p[1]
        const prevHist = prevP[1]
        if (hist >= 0) {
          color = 0
          if (hist < prevHist) color = 1
        } else {
          color = 2
          if (hist > prevHist) color = 3
        }
        const flag = p[4]
        let isUpward = flag === 1
        let isflagExist = flag !== 0
        ctx.strokeStyle = settings.histColor
        ctx.beginPath()
        if (isflagExist) {
          let arrowX = layout.t2screen(p[0])
          let arrowY = layout.$2screen(p[2])
          let arrowImgSrc;
          if(this.getArrow){
             arrowImgSrc = isUpward
            ? greenArrow
            : redArrow
          }
          const arrowImg = new Image()
          arrowImg.src = arrowImgSrc
          const arrowHeight = 10 // Adjust based on the actual height of your arrow image
          const arrowOffset = 10 // Offset value for positioning the arrow
          if (isUpward) {
            // Green arrow below the MACD line
            arrowY += arrowHeight - 5
            // Ensure the green arrow is within the visible area
            arrowY = Math.max(arrowHeight, Math.min(arrowY, layout.height - 20))
          } else {
            // Red arrow above the MACD line
            arrowY -= arrowHeight + arrowOffset
            // Ensure the red arrow is within the visible area
            arrowY = Math.max(arrowHeight, Math.min(arrowY, layout.height - 20))
          }
          ctx.drawImage(arrowImg, arrowX - arrowHeight / 2, arrowY)
        }
        ctx.moveTo(x, base)
        ctx.lineTo(x, y)
        ctx.stroke()
        prevP = p
      }
      // MACD LINE
      ctx.beginPath()
      ctx.lineWidth = this.macd_width
      ctx.strokeStyle = settings.macdColor
      for (const p of this.$props.data) {
        const x = layout.t2screen(p[0])
        const y = layout.$2screen(p[2])
        ctx.lineTo(x, y)
      }
      ctx.stroke()
      // SIGNAL LINE
      ctx.beginPath()
      ctx.lineWidth = this.signal_width
      ctx.strokeStyle = settings.signalColor
      for (const p of this.$props.data) {
        const x = layout.t2screen(p[0])
        const y = layout.$2screen(p[3])
        ctx.lineTo(x, y)
      }
      ctx.stroke()
    },
    draw_label(ctx, x, y, p, fillStyle) {
      ctx.fillStyle = fillStyle
      ctx.textAlign = 'center'
      // ctx.font = this.new_font
      ctx.fillText(p, x, y)
    },
    use_for() {
      return ['MACD']
    },
    data_colors() {
      return [this.macd_Color,this.hist_Color,this.signal_Color]
    },
    legend(values) {
      let xs = values.slice(1, 4).map(x => {
        return x.toFixed(3)  //(Math.abs(x) > 0.001 ? 4 : 8)
      // let xs = values.slice(1, 4).map(x => {
      // return x.toFixed(Math.abs(x) > 0.0 ? 2 : 3)
      })
      //console.log(xs)

      return [
        { value: xs[0], color: this.macd_Color },
        { value: xs[1], color: this.signal_Color },
        { value: xs[2], color: this.hist_Color }
      ]
    },
    y_range(hi, lo) {
      return [hi, lo]
    },    
    
  },
  computed: {
   
    /** @returns {Object} */
    sett() {
      // console.log(JSON.stringify(this.$props.settings))
      return this.$props.settings
    },
    /** @returns {Number} */
    hist_width() {
      return this.sett.histWidth || 4
    },
    /** @returns {Number} */
    macd_width() {
      if(this.sett.macdWidth){
        return  this.sett.macdWidth
      }else{
        return 1
      }
       
    },
    /** @returns {Number} */
    signal_width() {
      return this.sett.signalWidth || 1
    },
    /** @returns {String} */
    color() {
      return this.sett.defColor || '#42b28a'
    },
    hist_colors() {
      return ['#35a776', '#79e0b3', '#e54150', '#ea969e']
    },
    macd_Color(){
      return this.sett.macdColor || '#3782f2'
    },
    signal_Color(){
      return this.sett.signalColor || '#3782f2'
    },
    hist_Color(){
      return this.sett.histColor || '#3782f2'
    },
    ...mapGetters(['getArrow']),
   
  },
  watch:{
  }
}
</script>


