<template>
    <div class="bg-white rounded-[4px] w-full dark:bg-[#2d2d2d] dark:border-none shadow-lg">
      <div
        v-for="(option,i) in options"
        :key="i"
        class="pl-4 pr-8 py-3 hover:bg-gray-100 w-full dark:bg-[#2d2d2d] dark:hover:bg-[#3f3f3f] dark:text-white items-center flex space-x-3 cursor-pointer"
        @click="selectOption(option)"
      >
      <div v-if="getTheme">
        <img :src="require(`../../assets/chart/${option.image}`)" />
      </div>
      <div v-else>
        <img :src="require(`../../assets/chart/light/${option.image}`)" />
      </div>
      <div>
          {{ option.label }}
      </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IconDropdown',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(['getTheme']),
    },
  methods: {
    selectOption(option) {
      this.$emit('handlePress', option);
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
