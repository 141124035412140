import axios from 'axios';

let cancelToken;

const postRequest = async (url, data) => {
  // Check if there are any previous requests
  if (cancelToken) {
    // Cancel the previous request
    cancelToken.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken
  cancelToken = axios.CancelToken.source();

  try {
    const response = await axios.post(url, data, {
      cancelToken: cancelToken.token,
    });

    // Handle the response
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      // Handle the error
      console.error("Error making request:", error);
    }
  }
};

export default postRequest;