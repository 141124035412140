import { render, staticRenderFns } from "./ToolbarItem.vue?vue&type=template&id=612f0c45"
import script from "./ToolbarItem.vue?vue&type=script&lang=js"
export * from "./ToolbarItem.vue?vue&type=script&lang=js"
import style0 from "./ToolbarItem.vue?vue&type=style&index=0&id=612f0c45&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports