<template>
    <div class="w-full h-full justify-between flex">
        <div class="w-[50%] pr-4 border-r h-fit flex gap-y-8 pb-[2rem] dark:border-[#181818] flex-col">
            <div class="w-full flex gap-y-4 flex-col" v-for="(item,index) in chartSettingData" :key="index">
                <div class="flex items-center space-x-3" :key="index">
                    <span class="text-[#000000] font-[700] dark:text-[#F6F6F6] text-[16px]">
                        {{ item?.heading }}
                    </span>
                    <span v-if="item?.showToggle">
                        <button @click="item.isToggle = !item.isToggle; updateChartSetting()" :class="item.isToggle?'bg-[#037950]':'bg-[#989898]'" class='relative w-9 h-5 flex items-center  rounded-full cursor-pointer'>
                      <div :class="item.isToggle ? 'translate-x-4' : 'translate-x-0.5'" class='w-[1.1rem] h-[1.1rem] bg-white rounded-full shadow-md transform transition-transform' />
                  </button>
                    </span>
                </div>
 
                <div v-for="(sett,index) in item.settings">
                    
                    <div v-if="sett?.isGroup?.length > 0" class="w-full space-y-3 rounded-[12px] bg-[#EFEFEF] dark:bg-[#181818]">
                        <div v-for="(val,ind) in sett?.isGroup" :key="ind" :class="customClass(item,val)" class="w-full p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]">
                    <div>
                        <div v-if="val?.type == 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                            <input type="color" v-model="val.defaultValue" @input="updateChartSetting()"  class="w-full h-full cursor-pointer bg-transparent"  >
                        </div>
                        <div v-if="val?.type == 'checkbox'" :class="val.defaultValue ?'text-[#037950]':'text-[#989898] '" class="py-1.5 mx-1 cursor-pointer  flex rounded-full overflow-hidden">
                            <svg class="w-[20px] h-[20px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z" fill="currentColor"/>
                            </svg>

                        </div>
                    </div>
                    <div class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]">
                        {{ val?.name }}
                    </div>
                    </div>
                    </div>
                    <div v-else :class="customClass(item,sett)" :key="index" @click="handleCheckBoxes(item,sett)" class="w-full p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]">
                    <div>
                        <div v-if="sett?.type == 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                            <input type="color" v-model="sett.defaultValue" @input="updateChartSetting()" class="w-full h-full cursor-pointer bg-transparent" >
                        </div>
                        <div v-if="sett?.type == 'checkbox'" :class="sett.defaultValue ?'text-[#037950]':'text-[#989898] '" class="py-1.5 mx-1 cursor-pointer  flex rounded-full overflow-hidden">
                            <svg class="w-[20px] h-[20px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z" fill="currentColor"/>
                            </svg>

                        </div>
                    </div>
                    <div class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]">
                        {{ sett?.name }}
                    </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- <div class="w-[1px] h-full bg-[#e7e7e7]" /> -->
        <div class="w-[48%] h-fit flex gap-y-8 pb-[2rem] flex-col">
            <div class="w-full flex gap-y-4 flex-col" v-for="(item,index) in themeSetting" :key="index">
                <div class="flex items-center space-x-3" :key="index">
                    <span class="text-[#000000] dark:text-[#ffffff] font-[700] text-[16px]">
                        {{ item?.heading }}
                    </span>
                </div>

                <div v-for="(sett,index) in item.settings">
                    <div v-if="sett?.isGroup?.length > 0" class="w-full space-y-3 rounded-[12px] bg-[#EFEFEF] dark:bg-[#181818]">
                        <div v-for="(val,ind) in sett?.isGroup" :key="ind" @click="handleCheckBoxes(item,val)" :class="customClass(item,val)" class="w-full p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]">
                    <div>
                        <div v-if="val?.type == 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                            <input type="color" class="w-full h-full cursor-pointer bg-transparent" :value="val.defaultValue">
                        </div>
                        <div v-if="val?.type == 'checkbox'" :class="val.defaultValue ?'text-[#037950]':'text-[#989898] '" class="py-1.5 mx-1 cursor-pointer  flex rounded-full overflow-hidden">
                            <svg class="w-[20px] h-[20px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z" fill="currentColor"/>
                            </svg>

                        </div>
                    </div>
                    <div class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]">
                        {{ val?.name }}
                    </div>
                    </div>
                    </div>
                    <div v-else :key="index" @click="handleCheckBoxes(item,sett)" class="w-full cursor-pointer p-1 flex items-center space-x-2 rounded-full bg-[#EFEFEF] dark:bg-[#181818]">
                    <div>
                        <div v-if="sett?.type == 'color'" class="w-[2.2rem] h-[2.2rem] flex rounded-full overflow-hidden">
                            <input type="color" class="w-full h-full cursor-pointer" :value="sett.defaultValue">
                        </div>
                        <div v-if="sett?.type == 'checkbox'" :class="sett.defaultValue ?'text-[#037950]':'text-[#989898] '" class="py-1.5 mx-1  flex rounded-full overflow-hidden">
                            <svg class="w-[20px] h-[20px]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM9.6 18L3.6 12L5.292 10.308L9.6 14.604L18.708 5.496L20.4 7.2L9.6 18Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                    <div class="text-[#3D3D3D] font-[400] text-[14px] dark:text-[#F6F6F6]">
                        {{ sett?.name }}
                    </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "StyleTheme",
    props: {
        themeSettingProp: Array,
        chartSettingProp: Array
    },
    data() {
      return {
        chartSettingData:JSON.parse(JSON.stringify(this.chartSettingProp)),
        themeSetting:JSON.parse(JSON.stringify(this.themeSettingProp)),
        // themeSetting:[
        // {
        //         heading:'Theme',
        //         key:'theme',
        //         settings:[
        //             {isGroup:[
        //                 {name:'Legacy Charts',type:'checkbox',defaultValue:true,key:'legacyChart'},
        //                 {name:'Yahoo Charts',type:'checkbox',defaultValue:false,key:'yahooChart'},
        //                 {name:'Standard Green/Red',type:'checkbox',defaultValue:false,key:'standardGR'},
        //                 {name:'Standard Blue/Red',type:'checkbox',defaultValue:false,key:'standardBR'},
        //                 {name:'Greyscale',type:'checkbox',defaultValue:false,key:'grayScale'}
        //             ]}
        //         ]
        //     },
        //     {
        //         heading:'Dark/Light Mode',
        //         key:'darkLightMode',
        //         settings:[
        //             {isGroup : [
        //                 {name:'System Preference',type:'checkbox',defaultValue:true,key:'system'},
        //             {name:'Dark Mode',type:'checkbox',defaultValue:false,key:'dark'},
        //             {name:'Light Mode',type:'checkbox',defaultValue:false,key:'light'},
        //             ]}
                   
        //         ]
        //     },
        //     {
        //         heading:'Miscellaneous',
        //         key:'miscellaneous',
        //         settings:[
        //             {name:'Support & Resistance Lines',type:'checkbox',defaultValue:true,key:'supportLine'},
        //             {name:'Red & Green Arrows',type:'checkbox',defaultValue:true,key:'redGreenArrow'},
        //         ]
        //     },
        // ]
      };
    },
   methods: {
    updateChartSetting() {
      this.$emit('update-chart-settings', this.chartSettingData);
    },
    updateParentTheme() {
      this.$emit('update-theme-settings', this.themeSetting);
    },
    customClass(item,sett) {
        if(item?.key == 'upDownBarChart'){
            if(sett?.key == 'barChartColor'){
                if(item?.isToggle){
                    return 'pointer-events-none opacity-40 dark:opacity-50'      
                }else{
                    return ''      
                }
            }
            if(sett?.key == 'barUpColor' || sett?.key == 'barDownColor'){
                if(item?.isToggle){
                    return ''      
                }else{
                    return 'pointer-events-none opacity-40 dark:opacity-50'      
                }
            }
        }  

        if(item?.key == 'upDownVolume'){
            if(sett?.key == 'volumeColor'){
                if(item?.isToggle){
                    return 'pointer-events-none opacity-40 dark:opacity-50'      
                }else{
                    return ''      
                }
            }
            if(sett?.key == 'upVolumeColor' || sett?.key == 'downVolumeColor'){
                if(item?.isToggle){
                    return ''      
                }else{
                    return 'pointer-events-none opacity-40 dark:opacity-50'      
                }
            }
        } 

        if(item?.key == 'crossHair'){
            if(sett?.key == 'crossLineColor' || sett?.key == 'magnet'){
                if(item?.isToggle){
                    return ''      
                }else{
                    return 'pointer-events-none opacity-40 dark:opacity-50'      
                }
            }
        }  

     
    },
    handleCheckBoxes(item,sett){
    if(item.key == 'theme' || item.key == 'darkLightMode'){
      let themeList = item?.settings[0]?.isGroup
      const updatedArr = themeList?.map(val => {
            return {
                    ...val,
                    defaultValue: val.key === sett.key ? true : false
                };
            });
    

      this.themeSetting = this.themeSetting?.map(data => {
        if (data?.key === item?.key) {
          return {
            ...data,
            settings: [{
                isGroup: updatedArr
            }]
          };
        } else {
          return data;
        }
      })   

      this.$emit('update-theme-settings', this.themeSetting);
      console.log("themeList==>",this.themeSetting)

    }
    else if(item.key == 'miscellaneous'){
        let themeList = item?.settings
      const updatedArr = themeList?.map(val => {
            if(val.key == sett.key){
                return {
                    ...val,
                    defaultValue: !val?.defaultValue
                };
            }
            return val
            });
    
      this.themeSetting = this.themeSetting?.map(data => {
        if (data?.key === item?.key) {
          return {
            ...data,
            settings: updatedArr
          };
        } else {
          return data;
        }
      })  
      
      this.$emit('update-theme-settings', this.themeSetting);
      
    }
    else if(item.key == 'crossHair'){
        let themeList = item?.settings
      const updatedArr = themeList?.map(val => {
            if(val.key == sett.key){
                return {
                    ...val,
                    defaultValue: !val?.defaultValue
                };
            }
            return val
            });
    
      this.chartSettingData = this.chartSettingData?.map(data => {
        if (data?.key === item?.key) {
          return {
            ...data,
            settings: updatedArr
          };
        } else {
          return data;
        }
      })  
      
      this.$emit('update-chart-settings', this.chartSettingData);
      
    }
    
    }
   },
   watch: {
    themeSettingProp: {
      handler(newVal) {
        this.themeSetting = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true
    },
    chartSettingProp: {
      handler(newVal) {
        this.chartSettingData = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true
    },
  
  },
  };
  </script>
  
  <style scoped>

  

  input[type="color"] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      cursor: pointer;
    }

    /* Hide default color input's inner box */
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
      border-radius: 50%;
    }

    input[type="color"]::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
    }

    input[type="color"]::-moz-color-swatch {
      border: none;
      border-radius: 50%;
    }
  </style>
  