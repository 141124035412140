<script>
import {Overlay} from '../../@trading-vue-js/src/index'
export default {
  name: 'Tradeline',
  mixins: [Overlay],
  data() {
    return {}
  },
  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 1.5
    },
    color() {
      return this.sett.color || '#42b28a'
    }
  },
  methods: {
    meta_info() {
      return { author: 'C451', version: '1.1.2' }
    },
    formatTime(timestamp){
      const date = new Date(timestamp);

        // Format the date using Intl.DateTimeFormat
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      }).format(date);
    },
    draw(ctx) {
      ctx.lineWidth = this.line_width
      
      ctx.strokeStyle = this.color
      
      // console.log("this is layout from tradeline", this.$props)
      const layout = this.$props.layout
      const data = this.$props?.data
      // console.log("Tradeline",data?.length)
      // this.$props.points.forEach((p, i) => {
      //   console.log("Tradeline",p,i)  
      // })
      
      for (var k = 0, n = data.length; k < n; k++) {
        let p = data[k]
        // console.log(p)
        ctx.beginPath()
        let x2 = layout.t2screen(p[0])
        let x = layout.t2screen(p[1])
        let y = layout.$2screen(p[2])
        const moveToPoint = [x, y]
        const lineToPoint = [x2, y]
        // console.log("timeFrame == ",this.formatTime(p[0]),this.formatTime(p[1]))
        // console.log("moveToPoint == ",k,moveToPoint)
        // console.log("lineToPoint == ",k,lineToPoint)
        ctx.moveTo(...moveToPoint)
        ctx.lineTo(...lineToPoint)
        ctx.stroke()
      }
    },
    use_for() { return ['Tradeline'] },
  },
  mounted() {
    //console.log("Spline Mounted")
  }

}
</script>
