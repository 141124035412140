<template>
  <Multichart />
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Multichart from './Multichart.vue'

export default {
  name: 'App',
  data() {
    return {
      inputBackColor: 'white',
      SelectedItem: 'white'
    }
  },
  components: {
    Multichart
  },
  computed: {},
  mounted() {

  },

  watch: {}
}
</script>

<style  scoped>
</style>