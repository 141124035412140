<script>
import { Overlay } from '../../@trading-vue-js/src/index';
export default {
  name: "XMA",
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: "LakshanPerera",
        version: "1.0.0",
        desc: "Exponential Moving Average",
        preset: {
          name: "EMA",
          side: "onchart",
          settings: {
            lineWidth: 0.5,
            color: "#f7890c",
            lineType: "solid",
          },
        },
      };
    },
    use_for() {
      return ["XMA"];
    },
    calc() {
      return {
        props: {
          period: { def: 12, text: "Period" },
        },
        conf: { renderer: "Spline" },
        update: `
                    return ema(close, period)[0]
                `,
      };
    },
  },
};
</script>
