export const marketsData = [
    // {
    //     id: 1, selected: false, hide: false, desc: 'AMEX', code: 'AMEX', marketTime: {
    //         zone: 'America/New_York',
    //         startTime: 9,
    //         endTime: 17
    //     }
    // },
    {
        id: 2, selected: false, hide: false, desc: 'ASX', code: 'ASX', marketTime: {
            zone: 'Australia/Sydney',
            startTime: 9,
            endTime: 17
        }
    },
    {
        id: 3, selected: false, hide: false, desc: 'INDICES', code: 'INDX', marketTime: {
            zone: 'America/New_York',
            startTime: 9,
            endTime: 17
        }
    },
    {
        id: 4, selected: false, hide: false, desc: 'NASDAQ', code: 'NASDAQ', marketTime: {
            zone: 'America/New_York',
            startTime: 9,
            endTime: 17
        }
    },
    {
        id: 5, selected: false, hide: false, desc: 'NYSE', code: 'NYSE', marketTime: {
            zone: 'America/New_York',
            startTime: 9,
            endTime: 17
        }
    },
    {
        id: 6, selected: false, hide: false, desc: 'SGX', code: 'SG', marketTime: {
            zone: 'Asia/Singapore',
            startTime: 9,
            endTime: 17
        }
    },
];