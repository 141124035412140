<template>
    <div class="w-full gap-y-4 h-full flex py-4 flex-col items-center">
        <!-- <div @click="handleMagnet" :class="isMagnet?'dark:bg-[#2D2D2D] bg-[#F0F3FA]':''" class="cursor-pointer rounded-full p-2 dark:hover:bg-[#2D2D2D] hover:bg-[#F0F3FA]" title="magnet">
            <svg width="25" height="25" class="text-[#181818] dark:text-[white]" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="icomoon-ignore"> </g> <path d="M21.345 2.672v14.914c0 2.952-2.393 5.345-5.345 5.345-2.953 0-5.345-2.393-5.345-5.345v-14.914h-6.384v14.928c0 6.478 5.251 11.729 11.729 11.729s11.729-5.251 11.729-11.729v-14.928h-6.384zM26.663 3.738v3.199h-4.251v-3.199h4.251zM9.589 3.738v3.199h-4.251v-3.199h4.251zM16 28.262c-5.88 0-10.662-4.783-10.662-10.662v-9.596h4.251v9.583c0 3.535 2.876 6.411 6.411 6.411s6.411-2.876 6.411-6.411v-9.583h4.251v9.596c0 5.88-4.784 10.663-10.663 10.663z" fill="currentColor"> </path> </g></svg>
        </div> -->
        <!-- <div @click="selectTool('TrendLine:Trend')"   class="cursor-pointer rounded-full p-2 dark:hover:bg-[#2D2D2D] hover:bg-[#F0F3FA]" title="Trend Line">
            <svg width="30" height="30" class="text-gray-500 dark:text-[#ffffff80]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18 5C17.4477 5 17 5.44772 17 6C17 6.27642 17.1108 6.52505 17.2929 6.70711C17.475 6.88917 17.7236 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5ZM15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9C17.5372 9 17.0984 8.8948 16.7068 8.70744L8.70744 16.7068C8.8948 17.0984 9 17.5372 9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.3431 4.34315 15 6 15C6.46278 15 6.90157 15.1052 7.29323 15.2926L15.2926 7.29323C15.1052 6.90157 15 6.46278 15 6ZM6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19C6.55228 19 7 18.5523 7 18C7 17.7236 6.88917 17.475 6.70711 17.2929C6.52505 17.1108 6.27642 17 6 17Z" fill="currentColor"></path> </g></svg>
        </div> -->
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name:'LeftBar',
    props: {
        isMagnet: Boolean,
    },
    data() {
     return {
     }   
    },
    computed: {
        ...mapGetters(["tv"]),
        
    },
    methods: {
        selectTool(toolID) {
            this.selectedTool = toolID
            if (this.tv) {
                this.tv.custom_event({
                event: "tool-selected",
                args: [toolID],
                });
            }
            },
        // handleMagnet(){
        //     this.$emit("onMagnetPress")
        // },
  
    },
    mounted() {
   
  },
}


</script>

<style scoped>

</style>