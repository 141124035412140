import moment from "moment";
import Vue from "vue";


const chartEvents = new Vue();

let instance = null;

export default class ChartEventNew {
    constructor() {
        // console.log("ChartEventNew",instance)
        if (!instance) {
            instance = chartEvents;
        }

        return instance;
    }

    // Other methods and properties of the singleton class
}

export function GetIterationData(timeFrame) {
    let iterations = 5;
    let type = "day";
    if (timeFrame === "W") {
        iterations = 2
        type = "week"
    } else if (timeFrame === "M") {
        iterations = 1
        type = "month"
    }
    return {iterations, type};
}
export function GetTimeRange(candleData,range = "",timeFrame=""){
    console.log("props",candleData,range,timeFrame)
    let calculatedStartingTimeForYears
    let endingTime = candleData[candleData.length - 1][0];
    let startingTime = candleData[0][0];
    const {iterations, type} = GetIterationData(timeFrame)
    if (range === '1M') {
        // let calculateThreeM = moment(endingTime).subtract(10, 'days');
        let calculateThreeM = moment(endingTime).subtract(1, 'months');
        startingTime = calculateThreeM.valueOf()
        // console.log("calculateThreeM",calculateThreeM.toISOString())
    }
   else if (range === '3M') {
        // let calculateThreeM = moment(endingTime).subtract(10, 'days');
        let calculateThreeM = moment(endingTime).subtract(3, 'months');
        startingTime = calculateThreeM.valueOf()
        console.log("start",startingTime)
        // console.log("calculateThreeM",calculateThreeM.toISOString())
    }
    else if (range === '6M') {
        let calculateSixM = moment(endingTime).subtract(6, 'months');
        startingTime = calculateSixM.valueOf()
        // console.log("calculateSixM",calculateSixM.toISOString())
        // console.log("hello",startingTime)
    }
    else if (range === 'YTD') {
        // console.log('ytd')
        startingTime = moment().startOf('year').valueOf()
        // console.log("hello",startingTime)
    }
    else if (range.includes('Y')) {
        const numOfYears = parseInt(range.substring(0, range.length - 1))
        calculatedStartingTimeForYears = moment(endingTime)
        .subtract(numOfYears, 'year')
        .valueOf()
        // console.log(
        //   'valid date:',
        //   moment(calculatedStartingTimeForYears).format('LLLL')
        // )
        let checkValidDate = moment(calculatedStartingTimeForYears)
        if (checkValidDate.isValid()) {
            startingTime = calculatedStartingTimeForYears;
        }
    }
    else if (range.includes('All')) {
        const numOfYears = parseInt(range.substring(0, range.length - 1))
        calculatedStartingTimeForYears = moment(endingTime).subtract(50, 'year').valueOf()
        // console.log(
        //   'valid date:',
        //   moment(calculatedStartingTimeForYears).format('LLLL')
        // )
        let checkValidDate = moment(calculatedStartingTimeForYears)
        if (checkValidDate.isValid()) {
            startingTime = calculatedStartingTimeForYears;
        }
    }
    // console.log("end time before",endingTime)
    endingTime = moment(endingTime).add(iterations,type).valueOf()
    // console.log("end time",endingTime)
    return [startingTime,endingTime]
}

export const trendConfig = [
    {
        id: 1,
        name: "Technical",
        image:'indicator.svg',
        list: [
            {
                id: 1,
                name: "SMAX",
                desc: "Simple Moving Average",
                type:'Spline',
                label:{},
                settings:{},
                chartType:'onchart',
                data:[]
            },
            {
                id: 4,
                name: "WMA",
                chartType:'onchart',
                desc: "Weighted Moving Average",
            },
            {
                id: 5,
                name: "XMA",
                chartType:'onchart',
                desc: "Exponential Moving Average",
            },
            {
                id: 6,
                name: "SAR",
                chartType:'onchart',
                desc: "Parabolic Stop and Reverse",
            },
            {
                id: 7,
                name: "MACD",
                desc: "Moving Average Convergence/Divergence",
                chartType:'offchart',
                type:'MACD',
                data:[],
                settings: {
                    macdColor: '#3782f2',
                    signalColor: '#f48709',
                    histColor: '#9954bb',
                    histWidth: 4,
                     macdWidth: 1,
                    signalWidth: 1,
                    crosses: [],
                  },
            },
            {
                id: 8,
                name: "RSI",
                chartType:'offchart',
                desc: "Relative Strength Index",
            },
            {
                id: 9,
                name: "Stoch",
                chartType:'offchart',
                desc: "Stochastic",
            },
            {
                id: 10,
                name: "WilliamsR",
                chartType:'offchart',
                desc: "Williams %R",
            },
            {
                id: 11,
                name: "TCI",
                chartType:'onchart',
                desc: "Trend Channel Indicator",
                selected: false,
              
            },
            {
                id: 12,
                name: "BB",
                chartType:'onchart',
                desc: "Bollinger Bands",
            },
            {
                id: 13,
                name: "ATR",
                chartType:'offchart',
                desc: "Average True Range",
             
            },
            
            {
                id: 14,
                name: "ROC",
                chartType:'offchart',
                desc: "Rate of Change",
              
            },
            {
                id: 15,
                name: "VWAP",
                chartType:'offchart',
                desc: "VWAP indicator",
            },
            // {
            //     id: 16,
            //     name: "ADL",
            //     chartType:'offchart',
            //     desc: "ACCUMULATION DISTRIBUTION LINE",
            // },
            // {
            //     id: 17,
            //     name: "ADX",
            //     chartType:'offchart',
            //     desc: "Average Directional Index",
            // },
            {
                id: 18,
                name: "CCI",
                chartType:'offchart',
                desc: "Commodity Channel Index",
            },
            // {
            //     id: 19,
            //     name: "inertia",
            //     chartType:'offchart',
            //     desc: "INERTIA",
            // },
            // {
            //     id: 20,
            //     name: "stochRsi",
            //     chartType:'offchart',
            //     desc: "STOCHASTIC RSI",
            // },
            {
                id: 21,
                name: "CovalData",
                chartType:'offchart',
                desc: "COVAL TREND INDICATOR",
            },
            {
                id: 22,
                name: "ALMA",
                chartType:'onchart',
                desc: "ALMA",
            },
            {
                id: 23,
                name: "ATRp",
                chartType:'offchart',
                desc: "ATR%",
            },
            {
                id: 24,
                name: "BBW",
                chartType:'offchart',
                desc: "Bollinger Bands Width",
                settings: {
                    "color": "#2cc6c9ab"
                }
            },
            {
                id: 25,
                name: "CMO",
                chartType:'offchart',
                desc: "Chande Momentum Oscillator, 10",
                settings: {
                    "color": "#559de0"
                }
            },
          
            {
                name: "DMI",
                chartType:'offchart',
                desc: "Directional Movement Index",
                settings: {
                    "colors": ["#ef1360", "#3782f2", "#f48709"]
                }
            },
            {
                name: "HMA",
                chartType:'onchart',
                desc: "Hull Moving Average",
                settings: {
                    "color": "#3af475",
                    "length": 25
                }
            },
            {
                name: "KC",
                chartType:'onchart',
                desc: "Keltner Channles",
                settings: {
                    "color": "#4c8dffab",
                "backColor": "#4c8dff0a"
                }
            },
          
            {
                name: "KCW",
                chartType:'offchart',
                desc: "Keltner Channles Width",
                settings: {
                    "color": "#4c8dffab"
                }
            },
          
            {
                name: "MFI",
                chartType:'offchart',
                desc: "Money Flow Index",
                settings: {
                    "color": "#85c427ee",
                "backColor": "#85c42711",
                "bandColor": "#aaaaaa",
                "upper": 80,
                "lower": 20
                }
            },
          
            {
                name: "MOM",
                chartType:'offchart',
                desc: "Momentum",
                settings: {
                    "color": "#bcc427ee"
                }
            },
          
            {
                name: "ROC",
                chartType:'offchart',
                desc: "Rate of Change",
                settings: {
                    "color": "#279fc4"
                }
            },
          
          
            {
                name: "Ribbon",
                chartType:'onchart',
                desc: "Moving Average Ribbon",
                settings: {
                    "colors": ["#3aaaf4ee"],
                    "start": 10,
                    "number": 10,
                    "step": 5
                }
            },
          
            {
                name: "SWMA",
                chartType:'onchart',
                desc: "Symmetrically Weighted Moving Average",
                settings: {
                    "color": "#e57440"
                }
            },
            {
                name: "VWMA",
                chartType:'onchart',
                desc: "Volume Weighted Moving Average",
                settings: {
                    "color": "#db0670"
                }
            },
          
            {
                name: "TSI",
                chartType:'offchart',
                desc: "True Strength Index",
                settings: {
                    "colors": ["#3bb3e4", "#f7046d"]
                }
            },
          
          
          
            
          
        ],
    },
    {
        id: 2,
        name: "Financial",
        image:'financial.svg',
        list: [
            {
                name: "COG",
                chartType:'offchart',
                desc: "Center of Gravity",
                settings: {
                    "color": "#559de0"
                }
            },
        ],
    },
    {
        id: 3,
        image:'community.svg',
        name: "Community",
        list: [
            // {
            //     id: 1,
            //     name: "ADI",
            //     desc: "Advancing Declining Issue",
            // },
        
            // {
            //     id: 2,
            //     name: "ADL",
            //     desc: "Advance Decline Line",
            // },
        
            // {
            //     id: 3,
            //     name: "AI",
            //     desc: "Arms Index (TRIN)",
            // },
        
          
        ],
    },
];