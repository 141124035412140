<template>
    <div v-draggable  :class="getTheme?'bg-[#2D2D2D]':'bg-white'" class="w-[25rem] min-h-[35vh] max-h-[80vh] oveflow-y-auto no-scrollbar  shadow-lg rounded-md">
        <div  class="w-full flex items-center border-b dark:border-[#3a3a3a] justify-between px-4 py-2">
                  
                  <span  class="font-[500] dark:text-[#F6F6F6] text-[16px]">
                {{getCurrentChartSetting?.name == "CovalData 0" ? "Coval Data" : getCurrentChartSetting?.name}}
            </span>
            <span @click="handleClose" class="cursor-pointer p-2 rounded-full hover:bg-[#F0F3FA] dark:hover:bg-[#3f3f3f]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 dark:text-white">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </span>
        </div>

        <div class="px-4 py-3">
          
            <div v-for="(value, key) in getCurrentChartSetting?.settings" :key="key" class="w-full flex space-y-4 items-center">
                <div class="w-5/12">
                    <label :for="key" v-if="!notShow?.includes(key)" class="text-[#292929] dark:text-[#F6F6F6] text-[1.2rem]">{{ keyName[key] }}:</label>
                </div>
                <div class="w-5/12">
                <input
                v-if="colorInputs.includes(key)"
                
                class="bg-transparent"
                type="color"
                v-model="getCurrentChartSetting?.settings.grid ? getCurrentChartSetting?.settings.grid[key] : getCurrentChartSetting?.settings[key]"
                :id="key"
                @change="handleChange(key, $event.target.value)"
                />
                <select
                v-else-if="key === 'lineType'"
                 :class="getTheme?'border !border-[#3a3a3a]':'border'"
                class="custom-select focus:outline-none px-2 bg-transparent dark:text-white py-2 rounded-md"
                v-model="getCurrentChartSetting?.settings[key]"
                :id="key"
                
                @change="handleChange(key, $event.target.value)"
                >
                <option value="solid"  >Solid</option>
                <option value="dotted" >Dotted</option>
                <option value="dashed" >Dashed</option>
                </select>
                <input
                v-else-if="numberInputs?.includes(key)"
                type="number"
                :class="getTheme?'border !border-[#3a3a3a]':'border'"
                class="focus:outline-none px-2 w-full py-2 bg-transparent border-[#3a3a3a] dark:text-white rounded-md"
                v-model.number="getCurrentChartSetting.settings.grid ? getCurrentChartSetting.settings.grid[key]: getCurrentChartSetting?.settings[key]"
                :id="key"
                :min="key === 'lineWidth' ? '0' : '1'"
                :step="key === 'lineWidth' ? '0.1' : '1'"
                @change="handleChange(key, $event.target.value)"
                />
    </div>
      </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name:'IndicatorSettingModal',
    props: {

    },
    data() {
     return {
      keyName :{
            lineWidth:'Line Width',
            color:'Color',
            period:'Period',
            lineType:'Line Type',
            inc: 'Inc',
            max: 'Max',
            start: 'Start',
            histColor: 'Hist Color',
            macdColor: 'Macd Color',
            signalColor: "Signal Color",
            backColor: "Back Color",
            bandColor: "Band Color",
            days: "Days",
            dColor: 'D Color', 
            kColor: "K Color",
            param_d: "Param D",
            param_k: "Param K",
            smooth: "Smooth",
            length: "Length",
            step: "Step",
            number: "Number",
            long: "Long",
            short: "Short",
            signal: "Signal",
            mult: "Mult",
            lower: "Lower",
            upper: "Upper",
            stddev: "Std Dev",
            sigma: "Sigma",
            offset: "Offset",
            // borderColor: "Border Color",
            // strokeDashArray: "Stroke Dash",
            histColor:"Hist Color",
            signalWidth: "Signal Width",
            histWidth: "Hist Width",
            macdWidth: "Macd Width",
            borderColor: "Border Color",
            strokeDashArray: "Stroke Dash",
            lineWidth: "Line Width",
            firstLine: 'First Line',
            secondLine: "Second Line"
        },
        notShow:['$uuid','$props','crosses','showMid','use_tr','colors'],
        numberInputs : ['strokeDashArray','lineWidth','macdWidth','signalWidth','histWidth','lineWidth','period','start','inc','max','param_d','param_k','smooth','days','stddev','length','lineWidth','number','step','mult','long','short','signal','upper','lower','offset','sigma'],
        colorInputs: ['backColor','firstLine','secondLine','borderColor','color','macdColor','signalColor','histColor','bandColor','dColor','kColor']
     }   
    },
    computed: {
    ...mapGetters(['getCurrentChartSetting','getTheme']),
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleChange(key, value) {
            console.log("{key, value}",{key, value});
            let change = {x:key, y:value}
            this.$emit('settingChange',  change)
        },
    },
    mounted() {
    this.dragHandle = this.$refs.dragHandle;
  },
}


</script>

<style scoped>

/* Additional styles to make the dropdown consistent across browsers */
.custom-select option {
  background-color: #f0f0f0; /* Ensure options have the same background */
}
.dark .custom-select option {
  background-color: #2d2d2d; /* Ensure options have the same background */
}
</style>