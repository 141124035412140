<script>
// Price/Time measurment tool
import { Overlay, Tool } from '../../@trading-vue-js/src/index'
import Pin from './primitives/pin.js'
import Math2 from './primitives/math.js'
import {mapGetters} from "vuex";
import ToolSavingMixins from "./ToolSavingMixins";
export default {
  name: 'RectangleTool',
  mixins: [Overlay, Tool,ToolSavingMixins],
  mounted () {
    let prevSettings = localStorage.getItem('RectangleTool');
    if(prevSettings) {
      prevSettings = JSON.parse(prevSettings)
      this.$emit("change-settings", prevSettings);
    }
  },
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.0.0' }
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Shape',
        type: 'Shape',
        hint: 'Rectangle Tool',
        data: [], // Default data
        settings: {
          color: '#23a776',
          lineColor: '#23a776',
          lineWidth: 2,
          lineStyle: 'solid',
          settingsList: ['color', 'lineWidth', 'lineStyle', 'lineColor']
        } // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(
        new Pin(this, 'p1', {
          hidden: this.shift
        })
      )
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, 'p2', {
          state: 'tracking',
          hidden: this.shift
        })
      )

      this.pins[1].on('settled', () => {
        this.set_state('finished')
        this.updateSettingsState(this)
        this.$emit('drawing-mode-off')
        // Deselect the tool in shiftMode
        if (this.shift) {
          this._$emit('custom-event', {
            event: 'object-selected',
            args: []
          })
        } else {
          this.$emit('object-selected')
        }
      })

      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      const layout = this.$props.layout
      // Background
      // ctx.globalAlpha = 0.3
      let x1 = layout.t2screen(this.p1[0])
      let y1 = layout.$2screen(this.p1[1])
      let x2 = layout.t2screen(this.p2[0])
      let y2 = layout.$2screen(this.p2[1])

      ctx.fillStyle = this.color

      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.line_color
      ctx.beginPath();
      //--- line style
      if (this.sett.lineStyle === 'dashed') {
        ctx.setLineDash([10, 10])
      } else if (this.sett.lineStyle === 'dotted') {
        ctx.setLineDash([1, 1])
      }

      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y1);
      ctx.lineTo(x2, y2);
      ctx.lineTo(x1, y2);
      ctx.closePath();

      ctx.fill();

      ctx.stroke()
      ctx.setLineDash([])

      // for collisions and moving the object
      this.collisions.push(this.make([x1, y1], [x2, y2]))

      ctx.globalAlpha = 1
      this.render_pins(ctx)
    },
    make(p1, p2) {
      return (x, y) => {
        return Math2.is_within_rectangle([x, y], p1, p2)
      }
    },
    use_for() {
      return ['RectangleTool']
    },
    data_colors() {
      return [this.color]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['themePrimaryColor','themeSecondaryColor','themeFontColor']),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 1
    },
    line_color() {
      return this.sett.lineColor || '#23a776'
    },
    color() {
      return this.sett.color || '#23a776'
    },
    borderColor() {
      return this.sett.borderColor || this.$props.colors.cross
    },
    back_color() {
      return this.sett.backColor || '#9b9ba316'
    },
    value_back() {
      return this.sett.valueBack || '#9b9ba316'
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text
    },
    prec() {
      return this.sett.precision || 2
    },
    new_font() {
      return '12px ' + this.$props.font.split('px').pop()
    },
    price() {
      return 'price' in this.sett ? this.sett.price : true
    },
    time() {
      return 'time' in this.sett ? this.sett.time : false
    },
    shift() {
      return this.sett.shiftMode
    }
  },
  data() {
    return {}
  },
  created() {
    this.updateSettingsState(this)
  }
}
</script>
