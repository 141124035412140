<script>
import { mapGetters } from 'vuex';
import {Overlay} from '../../@trading-vue-js/src/index'
import greenArrow from '../../assets/arrows-png/green-arrow.png'
import redArrow from '../../assets/arrows-png/red-rrow.png'

export default {
  name: 'CovalData',
  mixins: [Overlay],
  props: ['dataCross'],
  data() {
    return {
      COLORS: ['#42b28a', '#5691ce', '#612ff9', '#d50b90', '#ff2316'],
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getArrow']),
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 0.75
    },
    first_line(){
      return this.sett.firstLine || '#42b28a'
    },
    second_line(){
      return this.sett.secondLine || '#5691ce'
    },
    widths() {
      return this.sett.lineWidths || []
    },
    clrx() {
      let colors = this.sett.colors || []
      let n = this.$props.num
      if (!colors.length) {
        for (var i = 0; i < this.lines_num; i++) {
          colors.push(this.COLORS[(n + i) % 5])
        }
      }
      return colors
    },
    dataSet() {
      return this.$props?.data
    },
    propSet() {
      return this.$props
    },
    lines_num() {
      if (!this.dataSet[0]) return 0
      return this.dataSet[0].length - 1
    },
    // Don't connect separate parts if true
    skip_nan() {
      return this.sett.skipNaN
    },
  },
  methods: {
    meta_info() {
      return {
        author: 'Milad', 
        version: '1.1.0',
        desc: 'Coval Indicator',
        preset:{
          name: 'Coval Indicator',
          side:"offchart"
        }
      }
    },
    drawCrosses(ctx, layout) {
      ctx.beginPath();
      this.sett?.crosses?.forEach((p, i) => {
        const flag = p[3];
        let isUpward = flag === 1;
        let isflagExist = flag !== 0;

        if (isflagExist) {
          let arrowX = layout.t2screen(p[0]);
          let arrowY = layout.$2screen(p[2]);

          let arrowImgSrc;

          if(this.getArrow){
            arrowImgSrc =  isUpward ? greenArrow : redArrow
          }
          const arrowImg = new Image();
          arrowImg.src = arrowImgSrc;

          const arrowHeight = 10; // Adjust based on the actual height of your arrow image
          const arrowOffset = 10; // Offset value for positioning the arrow

          if (isUpward) {
            // Green arrow below the MACD line
            arrowY += arrowHeight - 5;
            // Ensure the green arrow is within the visible area
            arrowY = Math.max(arrowHeight, Math.min(arrowY, layout.height - 20));
          } else {
            // Red arrow above the MACD line
            arrowY -= arrowHeight + arrowOffset;
            // Ensure the red arrow is within the visible area
            arrowY = Math.max(arrowHeight, Math.min(arrowY, layout.height - 20));
          }

          ctx.drawImage(arrowImg, arrowX - arrowHeight / 2, arrowY);

        }
      });

      ctx.stroke();
    },
    draw(ctx) {
      for (var i = 0; i < this.lines_num; i++) {
        let _i = i % this.clrx.length
        if(i == 0){
          ctx.strokeStyle = this.first_line
        }else{
          ctx.strokeStyle = this.second_line
        }
          // ctx.strokeStyle = this.clrx[_i]
        ctx.lineWidth = this.widths[i] || this.line_width
        ctx.beginPath()
        this.draw_spline(ctx, i)
        ctx.stroke()
      }

      const layout = this.$props.layout
      this.drawCrosses(ctx, layout)
    },
    draw_label(ctx, x, y, p, fillStyle) {
      ctx.fillStyle = fillStyle
      ctx.textAlign = 'center'
      // ctx.font = this.new_font
      ctx.fillText(p, x, y)
    },
    draw_spline(ctx, i) {
      const layout = this.$props.layout
      const data = this.dataSet
      if (!this.skip_nan) {
        for (var k = 0, n = data.length; k < n; k++) {
          let p = data[k]
          let x = layout.t2screen(p[0])
          let y = layout.$2screen(p[i + 1])
          ctx.lineTo(x, y)
        }
      } else {
        var skip = false
        for (var k = 0, n = data.length; k < n; k++) {
          let p = data[k]
          let x = layout.t2screen(p[0])
          let y = layout.$2screen(p[i + 1])
          if (p[i + 1] == null || y !== y) {
            skip = true
          } else {
            if (skip) ctx.moveTo(x, y)
            ctx.lineTo(x, y)
            skip = false
          }
        }
      }
    },
    use_for() {
      return ['CovalData']
    },
    data_colors() {
      return this.clrx
    },
  },
}
</script>


