// In your main.js or a separate file
import Vue from 'vue';

Vue.directive('draggable', {
  inserted(el) {
    el.style.position = 'absolute';
    el.style.cursor = 'move';

    function isExcludedTarget(target) {
        return target.tagName === 'INPUT'
      }

    el.onmousedown = function (e) {

        if (isExcludedTarget(e.target)) {
            return; // Prevent dragging if interacting with excluded elements
          }


      const rect = el.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      function onMouseMove(e) {
        // Calculate the new position
        let newLeft = e.clientX - offsetX;
        let newTop = e.clientY - offsetY;

        // Get viewport dimensions
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Get element dimensions
        const elWidth = el.offsetWidth;
        const elHeight = el.offsetHeight;

        // Constrain the element within the viewport
        newLeft = Math.max(0, Math.min(newLeft, viewportWidth - elWidth));
        newTop = Math.max(0, Math.min(newTop, viewportHeight - elHeight));

        // Apply the new position
        el.style.left = `${newLeft}px`;
        el.style.top = `${newTop}px`;
      }

      function onMouseUp() {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      }

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      // Prevent text selection while dragging
      el.ondragstart = () => false;
    };
  }
});
