import  {mapGetters} from "vuex"
import moment from "moment";
export default {
    data(){
        return {
            settingsJson:'',
            gridRef:''
        }
    },
    methods:{
        registerMouseEvent(self){
            self.mouse.on('mouseup',()=>{
                let uid = self.$props.settings.$uuid
                if("finished" === self.$props.settings.$state){
                    let currentSettings = self.$props.settings;
                    // console.log("mouse up event ",currentSettings)
                    let {...currentSettingsData} = currentSettings;
                    let currentSettingsJson = JSON.stringify(currentSettingsData)
                    let changeOccurs = false
                    if (currentSettingsJson !== self.settingsJson) {
                        changeOccurs = true
                        self.settingsJson = currentSettingsJson
                    }
                    if(changeOccurs){
                        self.$emit('line-is-changed',uid,self.gridRef)
                    }
                }
            })
        },
        updateSettingsState(self){
            let settings = self.$props.settings;
            // let gridId = self.$props.grid_id
            // let uuid = settings.$uuid
            // let typeData = uuid.split(".",1)[0]
            // let gridType =
            console.log("p1",JSON.stringify(settings.p1))
            console.log("p2",JSON.stringify(settings.p2))
            console.log("p3",JSON.stringify(settings.p3))
            if(
            (settings.p1 && settings.p1[0] === 0)
            ){
                const p1Node = Math.round( self.layout.ti_map.t2i(self.p1[2]))
                console.log(p1Node,moment(self.p1[2]).format("L"),self.p1[2])
                if(this.p1[0] !== p1Node){
                  self.p1[0] = p1Node
                }
            }
            if((settings.p2 && settings.p2[0] === 0)){
                const p2Node = Math.round( self.layout.ti_map.t2i(self.p2[2]))
                console.log(p2Node,moment(self.p2[2]).format("L"),self.p2[2])
                if(this.p2[0] !== p2Node){
                  self.p2[0] = p2Node
                }
            }
            if((settings.p3 && settings.p3[0] === 0)){
                const p3Node = Math.round( self.layout.ti_map.t2i(self.p3[2]))
                console.log(p3Node,moment(self.p3[2]).format("L"),self.p3[2])
                if(this.p3[0] !== p3Node){
                  self.p3[0] = p3Node
                }
            }
            let gridRef = settings.gridRef;
            // console.log("gridRef = ",gridRef,self.$props.grid_id)
            if(self.$props.grid_id && !gridRef){
                let gridValues = Object.keys(self.$props.cursor.values[self.$props.grid_id])
                for (let gridValue of gridValues) {
                    if(gridValue !== "ohlcv"){
                        // console.log("gridValue == ",gridValue)
                        gridRef = gridValue.split("_")[0]
                        break;
                    }
                }
            }

            // let chartData = this.dc.data[typeData]
            // console.log(gridId,chartData)
            let { ...settingsJson} = settings;
            self.settingsJson = JSON.stringify(settingsJson)
            self.gridRef = gridRef
        },
    },
    created() {
        // console.log("ToolSaving Mixin")
    },
    computed:{
        ...mapGetters(['dc'])
    }
}
