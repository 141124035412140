import Vue from "vue";
import moment from "moment/moment";

const chartEvents = new Vue();

let instance = null;

export default class ChartEventNew {
    constructor() {
        // console.log("ChartEventNew",instance)
        if (!instance) {
            instance = chartEvents;
        }

        return instance;
    }

    // Other methods and properties of the singleton class
}

export function GetIterationData(timeFrame) {
    let iterations = 5;
    let type = "day";
    if (timeFrame === "W") {
        iterations = 2
        type = "week"
    } else if (timeFrame === "M") {
        iterations = 1
        type = "month"
    }
    return {iterations, type};
}
export function GetTimeRange(candleData,range = "",timeFrame=""){
    let calculatedStartingTimeForYears
    let endingTime = candleData[candleData.length - 1][0]
    let startingTime = candleData[0][0]
    const {iterations, type} = GetIterationData(timeFrame)
    if (range === '3M') {
        // let calculateThreeM = moment(endingTime).subtract(10, 'days');
        let calculateThreeM = moment(endingTime).subtract(3, 'months');
        startingTime = calculateThreeM.valueOf()
        // console.log("calculateThreeM",calculateThreeM.toISOString())
    }
    else if (range === '6M') {
        let calculateSixM = moment(endingTime).subtract(6, 'months');
        startingTime = calculateSixM.valueOf()
        // console.log("calculateSixM",calculateSixM.toISOString())
        // console.log("hello",startingTime)
    }
    else if (range === 'YTD') {
        // console.log('ytd')
        startingTime = moment().startOf('year').valueOf()
        // console.log("hello",startingTime)
    }
    else if (range.includes('Y')) {
        const numOfYears = parseInt(
            range.substring(0, range.length - 1)
        )
        calculatedStartingTimeForYears = moment(endingTime)
            .subtract(numOfYears, 'year')
            .valueOf()

        // console.log(
        //   'valid date:',
        //   moment(calculatedStartingTimeForYears).format('LLLL')
        // )
        let checkValidDate = moment(calculatedStartingTimeForYears)
        if (checkValidDate.isValid()) {
            startingTime = calculatedStartingTimeForYears;
        }
    }
    else if (range.includes('All')) {
        const numOfYears = parseInt(
            range.substring(0, range.length - 1)
        )
        calculatedStartingTimeForYears = moment(endingTime)
            .subtract(50, 'year')
            .valueOf()

        // console.log(
        //   'valid date:',
        //   moment(calculatedStartingTimeForYears).format('LLLL')
        // )
        let checkValidDate = moment(calculatedStartingTimeForYears)
        if (checkValidDate.isValid()) {
            startingTime = calculatedStartingTimeForYears;
        }
    }
    endingTime = moment(endingTime).add(iterations,type).valueOf()
    return [startingTime,endingTime]
}